


















































































































.banner-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;

  .swiper-box {
    height: 100%;
    .img-box {
      display: block;
      height: 100%;
      overflow: hidden;
    }
  }
  /deep/ .swiper-slide {
    background: #000;
    img {
      opacity: 0.5;
    }
  }
  /deep/ .swiper-slide-active {
    background: transparent;
    img {
      width: 800px;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      background: #fff;
      display: block;
      margin: auto;
      opacity: 1;
    }
  }
}

.swiper-pagination {
  width: 100%;
  text-align: center;
  display: none;

  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 4px;
    background: #3c3c3c;
    border-radius: 3px;
    opacity: 1;
    margin: 0 2px;
  }

  /deep/ .swiper-pagination-bullet-active {
    width: 17px;
    background: #ffd47d;
  }
}
.swiper-button-prev {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  left: 180px;
  background: rgba(0, 0, 0, 0.35);
  &::after {
    font-size: 20px;
  }
}
.swiper-button-next {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  background: rgba(0, 0, 0, 0.35);
  right: 180px;
  &::after {
    font-size: 20px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";