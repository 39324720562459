// 主要颜色
@basic: #00B64F;
@basic-light: #EAFFE6;
@yellow: #F5A623;
@yellow-light: #FFF8E4;
@red: #FC6475;
@gray: #8C9196;
// 文字颜色
@text-primary: #FFD787;
@text-basic: #404040;
@text-gray: #8B8B8B;
@text-white: #fff;
//边线颜色
@border-basic: #f3f3f3;
// 绝对定位层级
@min-z-index: 100;
@middle-z-index: 1000;
@max-z-index: 2000;
// 按钮样式
@button-bg-color: #00AFE8;


.scrollbar(@x:auto,@y:auto,@size:4px) {
  overflow-x: @x;
  overflow-y: @y;
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 2px;
    transition: all ease .5s;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    transition: all ease .5s;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent;
    transition: all ease .5s;
    position: absolute;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: @size;
      height: @size;
      background: fade(#000, 20%);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}


//
.d-flex(@j_c:center,@a_i:center,@direction:row) {
  display: flex;
  flex-direction: @direction;
  justify-content: @j_c;
  align-items: @a_i;
}


//单行隐藏
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/*清除浮动*/
.clearfix {
  *zoom: 1;

  &:after {
    display: table;
    line-height: 0;
    content: "";
    clear: both;

  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}
