


















































.breadcrumb {
	height: 40px;
	line-height: 40px;
}
/deep/ .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
	font-weight: 400;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";