












































































.manage-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  > .el-main {
    padding: 0;
    height: auto;
    flex-basis: 0;
  }
}

.manage-container {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0;
}

.scrollBar {
  height: 100%;
  flex: 1;
}

.right-container {
  padding-top: 0;
  position: relative;
  z-index: 0;
}

.left-aside {
  width: 250px;
  height: 100%;
  background: #fff;
  border-right: 1px solid @border-basic;

  /deep/ .scrollbar-wrapper {
    height: 100%;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";