.wiki-header {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  background: #404040;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.wiki-header .gamekee-logo {
  width: 150px;
  height: 30px;
  background: url("../../assets/images/logo1.png") no-repeat center;
  background-size: 100% auto;
  margin-left: 32px;
  margin-right: 12px;
}
.wiki-header .pal-wiki-logo {
  width: 194px;
  height: 48px;
  background: url("../../assets/images/pal-wiki-logo.png") no-repeat center;
  background-size: 100% auto;
  margin-left: 0px;
  margin-right: 12px;
}
.wiki-header .divider {
  display: inline-block;
  width: 1px;
  height: 24px;
  background-color: #76767A;
  margin: 0 10px;
}
.wiki-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
