:root[theme="dark"] {
  --zs-wiki-home-main-bg: rgba(0, 0, 0, 0.25);
  --zs-wiki-home-bg-img: url("~@/assets/zs_imgs/wiki-home-bg.png") no-repeat;
  --zs-nav-menu-bg: linear-gradient(180deg, #2d3344 0%, #343c45 100%);
  --zs-nav-menu-border-color: #42474f;
  --zs-nav-menu-text-color: #999999;
  --zs-nav-menu-text-hover-color: #fff;
  --zs-nav-menu-text-hover-bg: #ededed;
  --zs-input-bg: rgba(0, 0, 0, 0.45);
  --zs-input-text-color: #fff;
  --zs-nav-text-color: #dbdbdb;
  --zs-user-box-bg: #303745;
  --zs-user-box-avatar-bg: #3a4353;
  --zs-user-box-name-color: #fff;
  --zs-user-box-btn-bg: #d4d1cd;
  --zs-user-box-btn-color: #2a3035;
  --zs-user-box-logout-border-color: #3d4657;
  --zs-user-box-logout-text-color: #d0ccc4;
  --zs-wiki-banner-bg: rgba(255, 255, 255, 0.07);
  --zs-server-tab-color: #c2cbd2;
  --zs-server-tab-dot-bg: #c2cbd2;
  --zs-server-wrapper-bg: transparent;
  --zs-model-title-before: #a4e4ff;
  --zs-server-select-bg: #191d26;
  --zs-server-select-color: #a3bdd8;
  --zs-card-pool-text-color: #dbdbdb;
  --zs-card-pool-icon-color: rgb(161, 163, 164);
  --zs-card-pool-list-bg: #2a323b;
  --zs-card-pool-name-color: #d0ccc4;
  --zs-card-pool-more-color: #a1a3a4;
  --zs-card-pool-notice-color: #d0ccc4;
  --zs-qz-tag-bg: #a4e4ff;
  --zs-qz-tag-icon-color: #999;
  --zs-qz-btn-bg: #454f5d;
  --zs-qz-tab-active-color: #fff;
  --zs-qz-nav-border-color: rgba(255, 255, 255, 0.12);
  --zs-qz-nav-item-text-color: #d0ccc4;
  --zs-qz-nav-item-border-color: rgba(255, 255, 255, 0.07);
  --zs-qz-entry-title-color: #c2cbd2;
  --zs-qz-entry-bg-color: #292f37;
  --zs-qz-entry-text-color: #d0ccc4;
  --zs-qz-entry-date-color: #999;
  --zs-model-tab-more-bg: rgba(216, 216, 216, 0.16);
  --zs-model-tab-more-text-color: transparent;
  --zs-model-tab-title-text-color: #838383;
  --zs-model-tab-title-active-color: #fff;
  --zs-model-wrapper-item-name-color: #d0ccc4;
  --zs-model-wrapper-item-bg: #38414b;
  --zs-aside-bar-bg: rgba(165, 195, 255, 0.15);
  --zs-aside-bar-text-color: #aab9bf;
  --zs-aside-bar-pop-bg: url("~@/assets/zs_imgs/popover-box-bg.png");
  --zs-aside-bar-pop-content-bg: none;
  --zs-aside-bar-pop-content-color: #e8e8e8;
  --zs-aside-bar-pop-friend-bg: rgba(164, 202, 255, 0.11);
  --zs-aside-bar-pop-friend-color: #e8e8e8;
  --zs-aside-bar-pop-apply-admin-bg: #d4d1cd;
  --zs-aside-bar-pop-apply-admin-text-color: #2a3035;
  --zs-header-bg: #1d212b;
  --zs-header-btn-bg: #454f5d;
  --zs-header-btn-text-color: #fff;
  --zs-qz-bg-img: url("~@/assets/zs_imgs/page-bg.png");
  --zs-qz-card-bg: #2c2c2f;
  --zs-qz-tabs-scroll-bg: rgba(255, 255, 255, 0.07);
  --zs-qz-tabs-border-color: #404345;
  --zs-qz-tabs-text-color: #7c7c7c;
  --zs-qz-tabs-text-active-color: #6db7d5;
  --zs-qz-top-text-color: #d9d9d9;
  --zs-qz-article-des-color: #999;
  --zs-qz-article-option-text-color: #999;
  --zs-qz-hot-question-title-color: #c2cbd2;
  --zs-qz-nav-item-bg: rgba(166, 176, 205, 0.13);
  --zs-search-input-text-color: #fff;
  --zs-search-input-bg-color: rgba(0, 0, 0, 0.14);
  --zs-search-btn-bg-color: rgba(11, 160, 255, 0.28);
  --zs-search-btn-text-color: #fff;
  --zs-search-nav-text-color: #7c7c7c;
  --zs-search-nav-active-color: #6db7d5;
  --zs-search-empty-btn-bg: linear-gradient(180deg, #135874 0%, #479cb2 100%, #0c4554 100%);
  --zs-search-list-container-bg: rgba(255, 255, 255, 0.07);
  --zs-search-nav-border-color: #585858;
  --zs-search-dropdown-bg: #24252a;
  --zs-search-dropdown-arrow-bg: #24252a;
  --zs-search-article-border-color: rgba(255, 255, 255, 0.05);
  --zs-search-article-title-color: #fff;
  --zs-search-article-summary-color: #999;
  --zs-search-article-bottom-color: #999;
  --zs-search-pop-bg-color: rgb(42, 56, 68);
  --zs-search-pop-text-color: #e8e8e8;
  --zs-kc-tab-bottom-border-color: rgba(166, 176, 205, 0.13);
  --zs-kc-currtime-bg-color: rgba(255, 255, 255, 0.05);
  --zs-kc-currtime-font-color: #fff;
  --zs-kc-filter-active-font-color: #fff;
  --zs-kc-filter-inactive-font-color: #999999;
  --zs-kc-searchinput-font-color: #fff;
  --zs-kc-searchinput-placeholder-color: #999999;
  --zs-kc-searchinput-border-color: rgba(255, 255, 255, 0.09); 
  --zs-kc-searchinput-icon-color: #999999;
  --zs-kc-divider-color: #515151;
  --zs-kc-sort-icon-color: #999999;
  --zs-kc-sort-txt-color: #d0ccc4;
  --zs-kc-sortmenu-font-color: #c6c6c6;
  --zs-kc-sortmenu-bg-color: #211e25;
  --zs-kc-sortmenu-divider-color: rgba(255, 255, 255, 0.04);
  --zs-kc-sortmenu-arrow-bg-color: #211e25;
  --zs-kc-sortmenu-arrow-border-color: #37343b;
  --zs-kc-carditem-bg-color: rgba(86, 89, 99, 0.34);
  --zs-kc-carditem-role-bg-color: rgba(114, 121, 150, 0.4);
  --zs-kc-carditem-role-title-color: #fff;
  --zs-kc-carditem-role-desc-color: #98a7ca;
  --zs-kc-carditem-role-tag-color: #fff;
  --zs-kc-carditem-role-tag-bg-color: rgba(216, 216, 216, 0.16);
  --zs-kc-carditem-progress-bg-color: rgba(0, 0, 0, 0.28);
  --zs-kc-carditem-progress-font-color: #fff;
  --zs-kc-carditem-complete-color: #c0c0c0;
  --zs-kc-carditem-doing-color: #c29fff;
  --zs-kc-carditem-nostart-color: #c0c0c0;
  --zs-kc-carditem-complete-bg-color: #475176;
  --zs-kc-carditem-doing-bg-color: #554374;
  --zs-kc-carditem-nostart-bg-color: #3c7180;
  --zs-kc-tab-active-color: #6db7d5;
  --zs-kc-tab-inactive-color: #7c7c7c;
  --zs-kc-tab-scrolltop-bg-color: rgba(255, 255, 255, 0);
  --zs-kc-tab-scroll-bg-color: #2c2c2f;
  --zs-kc-hd-title-color: #fff;
  --zs-kc-title-logo: url("~@/assets/zs_imgs/kc-title.png");
  --zs-kc-hd-title-logo: url("~@/assets/zs_imgs/hd-title.png");
  --zs-server-dropdown-border-color: #3a4151;
}
:root[theme="light"] {
  --zs-wiki-home-main-bg: rgba(137, 141, 153, 0.6);
  --zs-wiki-home-bg-img: url("~@/assets/zs_imgs/white/wiki-home-bg.png") no-repeat;
  --zs-nav-menu-bg: #fff;
  --zs-nav-menu-border-color: #fff;
  --zs-nav-menu-text-color: #999999;
  --zs-nav-menu-text-hover-color: #333;
  --zs-nav-menu-text-hover-bg: #ededed;
  --zs-input-bg: rgba(255, 255, 255, 0.5);
  --zs-input-text-color: #fff;
  --zs-nav-text-color: #fff;
  --zs-user-box-bg: #fbf7f4;
  --zs-user-box-avatar-bg: #fff;
  --zs-user-box-name-color: #3a3a3a;
  --zs-user-box-btn-bg: #967a5f;
  --zs-user-box-btn-color: #fff;
  --zs-user-box-logout-border-color: #f1f1f1;
  --zs-user-box-logout-text-color: #666;
  --zs-wiki-banner-bg: #fff;
  --zs-server-tab-color: #5a4434;
  --zs-server-tab-dot-bg: #1a4054;
  --zs-server-wrapper-bg: rgba(182,169,151,0.48);;
  --zs-model-title-before: #B4725A;
  --zs-server-select-bg: #9c907f;
  --zs-server-select-color: #3a3a3a;
  --zs-card-pool-text-color: #000;
  --zs-card-pool-icon-color: rgb(120, 114, 110);
  --zs-card-pool-list-bg: rgba(112, 101, 90, 0.19);
  --zs-card-pool-name-color: #211f1c;
  --zs-card-pool-more-color: #333;
  --zs-card-pool-notice-color: #333;
  --zs-qz-tag-bg: #b4725a;
  --zs-qz-tag-icon-color: #432d1e;
  --zs-qz-btn-bg: #967a5f;
  --zs-qz-tab-active-color: #44413f;
  --zs-qz-nav-border-color: #ececec;
  --zs-qz-nav-item-text-color: #666;
  --zs-qz-nav-item-border-color: #f2f2f2;
  --zs-qz-entry-title-color: #424242;
  --zs-qz-entry-bg-color: rgba(222, 218, 216, 0.6);
  --zs-qz-entry-text-color: #333;
  --zs-qz-entry-date-color: #666;
  --zs-model-tab-more-bg: transparent;
  --zs-model-tab-more-text-color: #432d1e;
  --zs-model-tab-title-text-color: #838383;
  --zs-model-tab-title-active-color: #59524f;
  --zs-model-wrapper-item-name-color: #2c343f;
  --zs-model-wrapper-item-bg: rgba(112, 101, 90, 0.19);
  --zs-aside-bar-bg: #f0f0f0;
  --zs-aside-bar-text-color: #33312f;
  --zs-aside-bar-pop-bg: #555a61;
  --zs-aside-bar-pop-content-bg: #f6f4f0;
  --zs-aside-bar-pop-content-color: #505050;
  --zs-aside-bar-pop-friend-bg: #dcd9d7;
  --zs-aside-bar-pop-friend-color: #424242;
  --zs-aside-bar-pop-apply-admin-bg: #967a5f;
  --zs-aside-bar-pop-apply-admin-text-color: #fff;
  --zs-header-bg: #7b706a;
  --zs-header-btn-bg: #f3e8db;
  --zs-header-btn-text-color: #5a4434;
  --zs-qz-bg-img: url("~@/assets/zs_imgs/white/wiki-home-bg.png");
  --zs-qz-card-bg: #fff;
  --zs-qz-tabs-scroll-bg: #fff;
  --zs-qz-tabs-border-color: #e9e9e9;
  --zs-qz-tabs-text-color: #999;
  --zs-qz-tabs-text-active-color: #b4725a;
  --zs-qz-top-text-color: #222;
  --zs-qz-article-des-color: #666;
  --zs-qz-article-option-text-color: #999;
  --zs-qz-hot-question-title-color: #171717;
  --zs-qz-nav-item-bg: #f1f1f1;
  --zs-search-input-text-color: #8b8b8b;
  --zs-search-input-bg-color: #efefef;
  --zs-search-btn-bg-color: #967a5f;
  --zs-search-btn-text-color: #fff;
  --zs-search-nav-text-color: #1a1a1a;
  --zs-search-nav-active-color: #44413f;
  --zs-search-empty-btn-bg: #967a5f;
  --zs-search-list-container-bg: #fff;
  --zs-search-nav-border-color: #e9e9e9;
  --zs-search-dropdown-bg: #fff;
  --zs-search-dropdown-arrow-bg: #ebeef5;
  --zs-search-article-border-color: #e9e9e9;
  --zs-search-article-title-color: #171717;
  --zs-search-article-summary-color: #666666;
  --zs-search-article-bottom-color: #575757;
  --zs-search-pop-bg-color: #fff;
  --zs-search-pop-text-color: #666;
  --zs-kc-tab-bottom-border-color: #e9e9e9;
  --zs-kc-currtime-bg-color: rgba(0, 0, 0, 0.07);
  --zs-kc-currtime-font-color: #5a4434;
  --zs-kc-filter-active-font-color: #44413f;
  --zs-kc-filter-inactive-font-color: #666666;
  --zs-kc-searchinput-font-color: #44413f;
  --zs-kc-searchinput-placeholder-color: #999999;
  --zs-kc-searchinput-border-color: #ececec;
  --zs-kc-searchinput-icon-color: #999999;
  --zs-kc-divider-color: #cfcfcf;
  --zs-kc-sort-icon-color: #666666;
  --zs-kc-sort-txt-color: #666666;
  --zs-kc-sortmenu-font-color: #666666;
  --zs-kc-sortmenu-bg-color: #fff;
  --zs-kc-sortmenu-divider-color: #f9f9f9;
  --zs-kc-sortmenu-arrow-bg-color: #fff;
  --zs-kc-sortmenu-arrow-border-color: #f3f3f3;
  --zs-kc-carditem-bg-color: #f4efeb;
  --zs-kc-carditem-role-bg-color: #eae1d6;
  --zs-kc-carditem-role-title-color: #5a4434;
  --zs-kc-carditem-role-desc-color: #8b7261;
  --zs-kc-carditem-role-tag-color: #746c63;
  --zs-kc-carditem-role-tag-bg-color: rgba(150, 122, 95, 0.2);
  --zs-kc-carditem-progress-bg-color: rgba(41, 29, 26, 0.11);
  --zs-kc-carditem-progress-font-color: #5c4d4d;
  --zs-kc-carditem-complete-color: #746c63;
  --zs-kc-carditem-doing-color: #ab6600;
  --zs-kc-carditem-nostart-color: #167da2;
  --zs-kc-carditem-complete-bg-color: #c8bab0;
  --zs-kc-carditem-doing-bg-color: #f9c375;
  --zs-kc-carditem-nostart-bg-color: #74daff;
  --zs-kc-tab-active-color: #b4725a;
  --zs-kc-tab-inactive-color: #999999;
  --zs-kc-tab-scrolltop-bg-color: rgba(255, 255, 255, 0);
  --zs-kc-tab-scroll-bg-color: #fff;
  --zs-kc-hd-title-color: #5a4434;
  --zs-kc-title-logo: url("~@/assets/zs_imgs/kc-title-dark.png");
  --zs-kc-hd-title-logo: url("~@/assets/zs_imgs/hd-title-dark.png");
  --zs-server-dropdown-border-color: transparent;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";