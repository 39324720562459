.wiki-base {
  width: 100%;
  height: 100%;
}
.wiki-base .wiki-base-layout {
  width: 100%;
  height: 100%;
  /* overflow: auto; */
}
.wiki-base .wiki-base-layout .wiki-body {
  padding-top: 54px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
}
.wiki-base .wiki-base-layout .wiki-body .wiki-content {
  width: 1380px;
  padding-top: 16px;
  margin: auto;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
