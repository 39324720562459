











































































































































































.wiki-base {
  width: 100%;
  height: 100%;
  .wiki-base-layout {
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    .wiki-body {
      padding-top: 54px;
      padding-bottom: 30px;
      width: 100%;
      height: 100%;
      .wiki-content {
        width: 1380px;
        padding-top: 16px;
        margin: auto;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";