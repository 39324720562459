

















































.wiki-footer {
  background: #f7f7f7;
  height: 120px;
  .footer-content {
    width: 1300px;
    height: 100%;
    padding: 10px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  .row1,
  .row2 {
    font-size: 12px;
    color: #666;
    a {
      color: inherit;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";