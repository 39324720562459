.user-body {
  padding-top: 54px;
}
.user-body .wiki-content {
  padding-top: 16px;
  width: 960px;
  margin: auto;
}
.msg-main {
  display: flex;
}
.nav-box {
  width: 165px;
  background: #fff;
  margin-right: 16px;
  border-radius: 6px;
  padding: 0 20px;
}
.nav-box .item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: #666;
  cursor: pointer;
  position: relative;
}
.nav-box .item .red-point {
  background: red;
  border-radius: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  color: #fff;
  font-size: 12px;
  margin-left: 4px;
}
.nav-box .item.router-link-active {
  color: #171717;
  font-weight: 500;
}
.nav-box .item + .item {
  border-top: 1px solid #f3f3f3;
}
.pc-msg-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.pc-msg-main .router-view {
  flex: 1;
  background: #fff;
  min-height: 500px;
  border-radius: 6px;
  padding: 28px;
  margin-bottom: 20px;
  overflow: hidden;
}
.wap-body {
  padding-top: 0;
}
.wap-body .wiki-content {
  width: 100%;
  padding: 12px;
}
.wap-body .router-view {
  width: 100%;
  padding: 12px;
}
.wap-msg-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.wap-msg-main .nav-box {
  width: calc(100% - 24px);
  margin: auto;
  border-bottom: 1px solid #f3f3f3;
  padding: 0;
}
.wap-msg-main .nav-bar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  padding: 0;
}
.wap-msg-main .nav-bar .item {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: #8b8b8b;
  border: none;
  position: relative;
}
.wap-msg-main .nav-bar .item:hover {
  color: #8b8b8b;
}
.wap-msg-main .nav-bar .item.router-link-active {
  color: #000;
  font-weight: bold;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
