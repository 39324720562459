


































.svg-icon {
  display: inline-block;
  vertical-align: middle;

  .icon {
    width: 1em;
    height: 1em;
    line-height: 1em;
    fill: currentColor;
    overflow: hidden;
    vertical-align: middle;
    margin-top: -0.2em;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";