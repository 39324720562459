.user-body {
  background-color: #f0f1f5;
  margin: 0;
  min-height: 100vh;
  padding-top: 54px;
}
.user-body .wiki-content {
  padding-top: 16px;
  width: 960px;
  margin: auto;
}
.user-main {
  display: flex;
  justify-content: space-between;
}
.nav-box {
  width: 170px;
}
.nav-bar {
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
}
.nav-bar a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid #f3f3f3;
  color: #666;
  font-size: 15px;
  transition: 0.3s;
}
.nav-bar a:hover {
  color: #000;
}
.nav-bar a .iconfont {
  font-size: 24px;
  margin-right: 12px;
  color: #d8d8d8;
}
.nav-bar a.router-link-active {
  color: #171717;
}
.nav-bar a.router-link-active .iconfont {
  color: #171717;
}
.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
