








































































/deep/ .el-dialog {
  background: transparent;
}
/deep/ .el-dialog__header {
  padding: 0;
  display: none;
  .el-dialog__headerbtn {
    top: 6px;
    right: 6px;
  }
}
/deep/ .el-dialog__body {
  padding: 0;
}

.container {
  width: 303px;
  height: 318px;
  background: #ffffff;
  border-radius: 10px;
  border: 4px solid #2fceff;
  padding-top: 76px;
  .top-icon {
    height: 160px;
    height: 108px;
    position: absolute;
    top: -43px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .login-btn {
    width: 263px;
    height: 36px;
    background: #2fceff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin: 0 auto;
    border: none;
    box-shadow: none;
  }

  .tips-desc {
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    padding: 24px 20px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";