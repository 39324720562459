.wiki-footer {
  background: #f7f7f7;
  height: 120px;
}
.wiki-footer .footer-content {
  width: 1300px;
  height: 100%;
  padding: 10px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.wiki-footer .title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.wiki-footer .row1,
.wiki-footer .row2 {
  font-size: 12px;
  color: #666;
}
.wiki-footer .row1 a,
.wiki-footer .row2 a {
  color: inherit;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
