













































































.user-body {
  padding-top: 54px;
  .wiki-content {
    padding-top: 16px;
    width: 960px;
    margin: auto;
  }
}

.msg-main {
  display: flex;
}

.nav-box {
  width: 165px;
  background: #fff;
  margin-right: 16px;
  border-radius: 6px;
  padding: 0 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: #666;
    cursor: pointer;
    position: relative;

    .red-point {
      //position: absolute;
      //top: 0;
      //left: 90%;
      background: red;
      border-radius: 20px;
      height: 16px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      color: #fff;
      font-size: 12px;
      margin-left: 4px;
    }

    &.router-link-active {
      color: #171717;
      font-weight: 500;
    }

    & + .item {
      border-top: 1px solid @border-basic;
    }
  }
}

.pc-msg-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .router-view {
    flex: 1;
    background: #fff;
    min-height: 500px;
    border-radius: 6px;
    padding: 28px;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.wap-body {
  padding-top: 0;

  .wiki-content {
    width: 100%;
    padding: 12px;
  }

  .router-view {
    width: 100%;
    padding: 12px;
  }
}

.wap-msg-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;

  .nav-box {
    width: calc(100% - 24px);
    margin: auto;
    border-bottom: 1px solid @border-basic;
    padding: 0;
  }

  .nav-bar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0;

    .item {
      display: inline-flex;
      align-items: center;
      padding: 0 10px;
      color: #8b8b8b;
      border: none;
      position: relative;

      &:hover {
        color: #8b8b8b;
      }

      &.router-link-active {
        color: #000;
        font-weight: bold;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";