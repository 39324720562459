.app-download-card {
  background: url("../../../assets/images/app-download-card-bg.png") no-repeat;
  background-position: right bottom;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 18px;
  border-radius: 16px;
}
.app-download-card .download-qrcode {
  width: 104px;
  height: 104px;
  margin-right: 16px;
  padding: 6px;
  border: 1px solid #19b2ff;
  border-radius: 6px;
}
.app-download-card .download-qrcode img {
  width: 100%;
  height: 100%;
}
.app-download-card .download-tips .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #2e3033;
  margin-bottom: 5px;
}
.app-download-card .download-tips .desc {
  font-size: 14px;
  line-height: 24px;
  color: #2e3033;
}
.app-download-card .card-tag {
  position: absolute;
  top: 0;
  right: 0;
  width: 114px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 0px 8px;
  background-color: #d4f1ff;
  font-size: 12px;
  line-height: 16px;
  color: #314e5d;
}
.app-download-card .card-tag .wiki-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 16px;
  margin-right: 4px;
  margin-bottom: 2px;
}
.wiki-header {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #404040;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}
.wiki-header .l-wrapper {
  display: flex;
  align-items: center;
}
.wiki-header .l-wrapper .gamekee-logo {
  width: 150px;
  height: 30px;
  background: url("../../../assets/images/logo3.png") no-repeat center;
  background-size: 100% auto;
  margin-left: 32px;
  margin-right: 12px;
}
.wiki-header .l-wrapper .hero-logo {
  width: 150px;
  height: 50px;
  background: url("../../../assets/images/hero_logo.png") no-repeat left center;
  background-size: auto 50px;
  margin-left: 32px;
  margin-right: 12px;
}
.wiki-header .l-wrapper .go-home-btn {
  margin-left: 20px;
}
.wiki-header .r-wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-right: 20px;
}
.wiki-header .r-wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 15px;
}
.wiki-header .r-wrapper a .iconfont {
  margin-right: 5px;
  color: #00B64F;
  font-size: 14px;
}
.wiki-header .r-wrapper a:hover {
  color: #FFD787;
}
.wiki-header .r-wrapper .user img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.wiki-header .r-wrapper .user span {
  margin-left: 5px;
  font-size: 12px;
}
.wiki-header .r-wrapper .user-box {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
}
.wiki-header .r-wrapper .user-box:hover ul {
  display: block;
}
.wiki-header .r-wrapper .user-box ul {
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  background: #fff;
  padding: 5px 10px;
  box-shadow: 0 2px 5px #eee;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #666;
}
.wiki-header .r-wrapper .user-box ul li a {
  margin: 0;
  color: #666;
}
.wiki-header .r-wrapper .user-box ul li {
  cursor: pointer;
}
.wiki-header .r-wrapper .user-box ul li + li {
  border-top: 1px solid #f3f3f3;
}
.nav {
  height: 54px;
  display: flex;
}
.nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 100%;
  color: #fff;
  font-size: 13px;
}
.search-box {
  width: 160px;
  height: 36px;
  overflow: hidden;
  border-radius: 20px;
  background: #292929;
  position: relative;
  margin-right: 15px;
}
.search-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding-left: 16px;
  color: #fff;
}
.search-box .el-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #999999;
}
.apply-wiki-btn {
  width: 106px;
  height: 36px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 18px;
  border: 1px solid #525252;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}
.quit-teeny-btn {
  width: 95px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  line-height: 36px;
  cursor: pointer;
}
.msg-btn {
  position: relative;
}
.msg-btn span {
  position: absolute;
  top: -6px;
  left: 20px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  word-break: keep-all;
  background: red;
  border-radius: 10px;
  height: 16px;
  font-size: 12px;
  color: #fff;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
