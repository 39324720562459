.main-header {
  width: 100%;
  height: 60px;
}
.main-header .info-container {
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
.main-header .info-container .info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1140px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.main-header .info-container .info-bar .left {
  display: flex;
  align-items: center;
}
.main-header .info-container .info-bar .left .game {
  width: auto;
  height: 46px;
}
.main-header .info-container .info-bar .left .links {
  position: relative;
  width: 60px;
  height: 100%;
  text-align: center;
  line-height: 60px;
  color: #4b525b;
  font-size: 16px;
  text-decoration: none;
  transition: all ease 0.3s;
}
.main-header .info-container .info-bar .left .links:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  opacity: 0;
  width: 20px;
  height: 2px;
  background-color: #00b64f;
  transition: all ease 0.3s;
  transform: translateX(-50%);
}
.main-header .info-container .info-bar .left .links:hover {
  color: #00b64f;
}
.main-header .info-container .info-bar .left .links:hover:after {
  opacity: 1;
}
.main-header .info-container .info-bar .right {
  display: flex;
  align-items: center;
}
.main-header .info-container .info-bar .right .search {
  position: relative;
  width: 240px;
  height: 34px;
}
.main-header .info-container .info-bar .right .search input {
  width: 100%;
  height: 100%;
  padding: 0 40px 0 20px;
  border: none;
  box-sizing: border-box;
  border-radius: 30px;
  outline: none;
  background-color: #eeeeee;
}
.main-header .info-container .info-bar .right .search .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 34px;
  background-size: 24px 24px;
  cursor: pointer;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
