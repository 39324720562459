






















































.main-header {
  width: 100%;
  height: 60px;

  .info-container {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid @border-basic;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;

    .info-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1140px;
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: center;

        .game {
          width: auto;
          height: 46px;
        }

        .links {
          position: relative;
          width: 60px;
          height: 100%;
          text-align: center;
          line-height: 60px;
          color: #4b525b;
          font-size: 16px;
          text-decoration: none;
          transition: all ease 0.3s;

          &:after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 50%;
            opacity: 0;
            width: 20px;
            height: 2px;
            background-color: #00b64f;
            transition: all ease 0.3s;
            transform: translateX(-50%);
          }

          &:hover {
            color: #00b64f;
            // font-weight: bold;
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .search {
          position: relative;
          width: 240px;
          height: 34px;

          input {
            width: 100%;
            height: 100%;
            padding: 0 40px 0 20px;
            border: none;
            box-sizing: border-box;
            border-radius: 30px;
            outline: none;
            background-color: #eeeeee;
          }

          .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 34px;
            background-size: 24px 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";