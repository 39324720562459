


















































































































































































































































/deep/ .el-dialog__header {
  padding: 20px 20px 0 20px;
  .el-dialog__headerbtn {
    top: 16px;
    right: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 22px;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
}
.ques-list {
  width: 100%;
  .item {
    width: 100%;
    background: #f8f8f8;
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    margin-bottom: 10px;
    .item-title {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
    }
    .bottom {
      padding-top: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        font-size: 12px;
        color: #999;
        line-height: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        line-height: 14px;
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 6px;
        }
      }
    }
  }
}
.empty-box {
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";