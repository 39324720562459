





























































































































































































































































































































































































































































































.login-title {
  font-size: 18px;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/ .login-body {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  margin: 0 2px 35px 2px;
  .login-type-title {
    text-align: center;
    margin-bottom: 14px;
    line-height: 20px;
    color: #000;
  }
  .phone-login {
    padding: 18px 28px;
    width: 290px;
    border-right: 1px solid #e2e2e2;
    box-sizing: content-box;
    .el-form-item {
      margin-bottom: 14px;
    }
    .el-input__inner {
      background-color: #f7f7f7;
      border-color: transparent;
      border-radius: 8px;
    }
    .el-input__inner::-webkit-input-placeholder {
      font-size: 12px;
      color: #c2c2c2;
    }
    .el-divider--horizontal {
      margin-top: 24px;
    }
    .el-input__inner::-moz-placeholder {
      font-size: 12px;
      color: #c2c2c2;
    }
    .login-input-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .login-submit {
      width: 100%;
      border-radius: 100px;
      background-color: #2fceff;
      border: none;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
  }
  .wechat-login {
    padding-top: 18px;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-container {
      position: relative;
      width: 174px;
      height: 174px;
      background-color: #f7f7f7;
      .iframe-wrap {
        height: 160px;
        width: 160px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        iframe {
          position: absolute;
          left: -68px;
        }
      }
    }
  }
}
.img-box {
  display: flex;
  height: auto;
  height: 40px;
}

.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  color: #2fceff;
  right: 7px;
  &:hover {
    color: #2fceff;
  }
}

.other-login-title {
  color: fade(@text-basic, 50%);
  font-weight: 400;
}

.other-login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 25px;

  a {
    display: flex;
    width: 48px;
    height: 48px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #e8e8e8;

    &.qq-btn {
      background: url("./../../../assets/images/login-qq.png") no-repeat center #f7f7f7;
      background-size: 24px 24px;
    }

    &.weixin-btn {
      background: url("./../../../assets/images/login-wechat.png") no-repeat center #f7f7f7;
      background-size: 24px 24px;
    }
  }
}

/deep/ .xy {
  font-size: 11px;
  line-height: 16px;
  color: @gray;
  cursor: pointer;
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  .el-checkbox__inner,
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5a75a5;
    border-color: #5a75a5;
    border-radius: 50%;
  }
  a {
    color: #5a75a5;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";