.title-model-wrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 6px);
  margin: 0 1px 10px;

  .title-p {
    line-height: 1.8;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    font-size: 14px;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.title1 {
    overflow: hidden;
    background: #00B64F;
    padding-right: 50px;

    .title-p {
      color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 10px;
      width: 100px;
      height: 100%;
      background: url('../../assets/images/icon-title-right-arrow.png') no-repeat right center;
      background-size: auto 100%;
    }
  }

  &.title2 {
    background: #DDFCFF;
    border-top: 2px solid #00B64F;

    .title-p {
      color: #00B64F;
    }
  }

  &.title3 {
    border-bottom: 2px solid rgba(37, 182, 197, 0.5);
    padding-bottom: 6px;

    .title-p {
      line-height: 1.4;
      color: #00B64F;
    }

    &:before {
      content: '';
      width: 100%;
      border-bottom: 4px solid #00B64F;
      position: absolute;
      bottom: 2px;
    }
  }

  &.title4 {
    border-radius: 0 2em 2em 0;
    background: #00B64F;

    .title-p {
      color: #fff;
    }
  }

  &.title5 {
    .title-p {
      color: #4B525B;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      content: '';
      width: 3px;
      height: 14px;
      background: #00B64F;
      border-radius: 3px;
      transform: translateY(-50%);
    }
  }
}
.divider-line{
  height: 20px;
  position: relative;
  margin: 5px 0;
  display: inline-block;
  width: 99%;
  cursor: pointer;
  &.divider-line1{
    &:after{
      content: '';
      width: 100%;
      height: 1px;
      background: #727981;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.divider-line2{
    &:after{
      content: '';
      width: 100%;
      height: 2px;
      background: #727981;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.divider-line3{
    &:after{
      content: '';
      width: 100%;
      height: 1px;
      border-top: 1px dashed #727981;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.divider-line4{
    &:after{
      content: '';
      width: 100%;
      height: 2px;
      border-top: 2px dashed #727981;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.divider-line5{
    background: url("./../images/divider-bg5.png") no-repeat center;
    background-size: auto 18px;
  }
  &.divider-line6{
    background: url("./../images/divider-bg6.png") no-repeat center;
    background-size: auto 11px;
  }
}
