.pc-www-container {
  width: 100%;
  height: 100%;
}
.www-body {
  width: 1300px;
  margin: auto;
  padding-top: 69px;
  margin-bottom: 20px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
