 /deep/ .el-dialog__wrapper {
  backdrop-filter: blur(10px);
}
 /deep/ .underage-dialog .el-dialog__header {
  display: none;
}
.dialog-container .logo {
  width: 100%;
  text-align: center;
  margin-bottom: 41px;
  margin-top: 20px;
}
.dialog-container .logo img {
  width: 227px;
  height: 34px;
}
.dialog-container .desc {
  font-weight: 400;
  font-size: 14px;
  color: #171717;
  line-height: 20px;
  margin-bottom: 32px;
}
.dialog-container .desc p {
  text-align: center;
  margin: 0;
}
.dialog-container .btns {
  text-align: center;
  margin-bottom: 20px;
}
.dialog-container .btns button {
  width: 400px;
  height: 37px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.dialog-container .btns .text-btn {
  height: 20px;
  background-color: #fff;
  border: none;
  color: #bdbec1;
  margin-bottom: 20px;
}
.dialog-container .btns .primary-btn {
  background: #00afe8;
  border: 1px solid #00afe8;
  color: #fff;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
