.www-secondary-base {
  padding-top: 70px;
  min-height: 100vh;
}
.www-secondary-base .www-secondary-body {
  background: #fff;
  margin: auto;
  width: 1330px;
  min-height: 900px;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
