 /deep/ .dialog-normal {
  border-radius: 20px;
  background: linear-gradient(180deg, #fdffff 0%, #bdefff 100%);
}
 /deep/ .dialog-normal .login-title {
  position: absolute;
  z-index: 1;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
}
 /deep/ .dialog-normal .el-dialog__header {
  padding: 22px 0;
}
 /deep/ .dialog-normal .el-dialog__body {
  padding-top: 9px;
  background: url("../../../assets/images/login-bg-bottom.png") no-repeat;
  background-position: right bottom;
}
 /deep/ .dialog-normal .el-dialog__headerbtn {
  background-image: url("../../../assets/images/login-close.png");
  width: 36px;
  height: 36px;
  z-index: 1;
  background-size: contain;
  top: -12px;
  right: -12px;
  cursor: pointer;
  transition: transform 300ms;
}
 /deep/ .dialog-normal .el-dialog__headerbtn:hover {
  transform: rotate(90deg);
}
 /deep/ .dialog-normal .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
 /deep/ .dialog-verify .login-title {
  position: static;
  transform: translateX(0);
}
 /deep/ .protocol-dialog .footer {
  display: flex;
  justify-content: space-between;
}
 /deep/ .protocol-dialog .footer .no,
 /deep/ .protocol-dialog .footer .yes {
  width: 45%;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
 /deep/ .protocol-dialog .footer .no {
  color: #2fceff;
  background-color: #fff;
  border: 1px solid #2fceff;
}
 /deep/ .protocol-dialog .footer .yes {
  color: #fff;
  background-color: #2fceff;
}
 /deep/ .protocol-dialog .protocol-txt {
  color: #5a75a5;
}
 /deep/ .protocol-dialog .protocol-txt:active {
  color: #5a75a5;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
