.emoji-panel {
  width: 360px;
  height: 325px;
  user-select: none;
}
.emoji-panel /deep/ .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.emoji-panel /deep/ .el-tabs .el-tabs__content {
  flex-grow: 1;
}
.emoji-panel /deep/ .el-tabs .el-tabs__header {
  border-bottom-width: 0;
}
.emoji-panel /deep/ .el-tabs .el-tabs__header .is-bottom {
  margin-top: 0;
}
.emoji-panel /deep/ .el-tabs .el-tabs__header .el-tabs__nav-next,
.emoji-panel /deep/ .el-tabs .el-tabs__header .el-tabs__nav-prev {
  line-height: 36px;
}
.emoji-panel /deep/ .el-tabs .el-tabs__header .el-tabs__nav-prev {
  left: 4px;
}
.emoji-panel /deep/ .el-tabs .el-tabs__header .el-tabs__nav-next {
  right: 4px;
}
.emoji-panel /deep/ .el-tabs .el-tabs__nav-scroll {
  background-color: #f2f2f2;
}
.emoji-panel /deep/ .el-tabs .el-tabs__nav-scroll .el-tabs__nav {
  border: none;
}
.emoji-panel /deep/ .el-tabs .el-tabs__nav-scroll .el-tabs__nav .is-active {
  background-color: #fff;
}
.emoji-panel /deep/ .el-tabs .el-tabs__nav-scroll .el-tabs__item {
  height: 36px;
  line-height: initial;
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  border: none;
}
.emoji-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 17px 0;
  row-gap: 17px;
}
.emoji-grid img {
  width: 56px;
  height: 56px;
}
.tab-label {
  width: 22px;
  height: 22px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
