 /deep/ .dark {
  background: linear-gradient(to bottom, #2d3344, #343c45);
}
 /deep/ .dark .el-dialog__header {
  padding: 20px 20px 0 20px;
}
 /deep/ .dark .el-dialog__header .el-dialog__title {
  color: #fff;
}
 /deep/ .dark .el-dialog__header .el-dialog__headerbtn {
  top: 16px;
  right: 20px;
}
 /deep/ .dark .el-dialog__header .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff;
}
 /deep/ .dark .el-dialog__close {
  color: #ffffff;
}
 /deep/ .dark .article-edit-box {
  background-color: #20242f;
  color: #bebebe;
}
 /deep/ .dark .title {
  color: #fff;
  padding-top: 20px;
  border-top: 1px solid rgba(233, 233, 233, 0.12);
}
 /deep/ .dark .ques-list .item {
  background-color: transparent;
  border-bottom: 1px solid rgba(233, 233, 233, 0.04);
  border-radius: 0;
}
 /deep/ .dark .ques-list .item .item-title {
  color: #bebebe;
  border-bottom: none;
}
 /deep/ .dark .ques-list .item .bottom .user {
  color: #999999;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
