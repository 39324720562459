

















































































































































.pc-app {
  max-width: 100%;
  /* overflow: auto; */
}

// 页面置灰
.gary-body {
  -webkit-filter: grayscale(100%); /* webkit */
  -moz-filter: grayscale(100%); /*firefox*/
  -ms-filter: grayscale(100%); /*ie9*/
  -o-filter: grayscale(100%); /*opera*/
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";