.banner-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
}
.banner-container .swiper-box {
  height: 100%;
}
.banner-container .swiper-box .img-box {
  display: block;
  height: 100%;
  overflow: hidden;
}
.banner-container /deep/ .swiper-slide {
  background: #000;
}
.banner-container /deep/ .swiper-slide img {
  opacity: 0.5;
}
.banner-container /deep/ .swiper-slide-active {
  background: transparent;
}
.banner-container /deep/ .swiper-slide-active img {
  width: 800px;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  background: #fff;
  display: block;
  margin: auto;
  opacity: 1;
}
.swiper-pagination {
  width: 100%;
  text-align: center;
  display: none;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 12px;
  height: 4px;
  background: #3c3c3c;
  border-radius: 3px;
  opacity: 1;
  margin: 0 2px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 17px;
  background: #ffd47d;
}
.swiper-button-prev {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  left: 180px;
  background: rgba(0, 0, 0, 0.35);
}
.swiper-button-prev::after {
  font-size: 20px;
}
.swiper-button-next {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  background: rgba(0, 0, 0, 0.35);
  right: 180px;
}
.swiper-button-next::after {
  font-size: 20px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
