












































































































































































































































































.app-download-card {
  background: url("../../../assets/images/app-download-card-bg.png") no-repeat;
  background-position: right bottom;
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 18px;
  border-radius: 16px;
  .download-qrcode {
    width: 104px;
    height: 104px;
    margin-right: 16px;
    padding: 6px;
    border: 1px solid #19b2ff;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #2e3033;
      margin-bottom: 5px;
    }
    .desc {
      font-size: 14px;
      line-height: 24px;
      color: #2e3033;
    }
  }
  .card-tag {
    position: absolute;
    top: 0;
    right: 0;
    width: 114px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 16px 0px 8px;
    background-color: #d4f1ff;
    font-size: 12px;
    line-height: 16px;
    color: #314e5d;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      line-height: 16px;
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}
.wiki-header {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #404040;
  position: fixed;
  z-index: @middle-z-index;
  top: 0;
  left: 0;

  .l-wrapper {
    display: flex;
    align-items: center;

    .gamekee-logo {
      width: 150px;
      height: 30px;
      background: url("./../../../assets/images/logo3.png") no-repeat center;
      background-size: 100% auto;
      margin-left: 32px;
      margin-right: 12px;
    }

    .hero-logo {
      width: 150px;
      height: 50px;
      background: url("./../../../assets/images/hero_logo.png") no-repeat left center;
      background-size: auto 50px;
      margin-left: 32px;
      margin-right: 12px;
    }

    .go-home-btn {
      margin-left: 20px;
    }
  }

  .r-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 20px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      transition: 0.3s;
      cursor: pointer;
      margin: 0 15px;

      .iconfont {
        margin-right: 5px;
        color: @basic;
        font-size: 14px;
      }

      &:hover {
        color: @text-primary;
      }
    }

    .user {
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .user-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;

      &:hover {
        ul {
          display: block;
        }
      }

      ul {
        display: none;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        background: #fff;
        padding: 5px 10px;
        box-shadow: 0 2px 5px #eee;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        color: #666;

        li {
          a {
            margin: 0;
            color: #666;
          }
        }

        li {
          cursor: pointer;
        }

        li + li {
          border-top: 1px solid @border-basic;
        }
      }
    }
  }
}

.nav {
  height: 54px;
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 100%;
    color: #fff;
    font-size: 13px;
  }
}

.search-box {
  width: 160px;
  height: 36px;
  overflow: hidden;
  border-radius: 20px;
  background: #292929;
  position: relative;
  margin-right: 15px;

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    padding-left: 16px;
    color: #fff;
  }

  .el-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #999999;
  }
}
.apply-wiki-btn {
  width: 106px;
  height: 36px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 18px;
  border: 1px solid #525252;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}

.quit-teeny-btn {
  width: 95px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  line-height: 36px;
  cursor: pointer;
}

.msg-btn {
  position: relative;
  span {
    position: absolute;
    top: -6px;
    left: 20px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    word-break: keep-all;
    background: red;
    border-radius: 10px;
    height: 16px;
    font-size: 12px;
    color: #fff;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";