








































































































































































































































































.nav-menu {
  background: var(--zs-nav-menu-bg) !important;
  border-radius: 8px !important;
  border: 1px solid var(--zs-nav-menu-border-color) !important;
  padding: 14px 10px 10px;
  /deep/ .el-dropdown-menu__item {
    font-size: 13px;
    font-weight: 400;
    background: transparent !important;
    height: 28px;
    min-width: 92px;
    margin-bottom: 4px;
    padding: 0 9px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: var(--zs-nav-menu-text-color);
    line-height: 28px;
    &:focus,
    &:hover {
      color: var(--zs-nav-menu-text-hover-color);
      background: var(--zs-nav-menu-text-hover-bg) !important;
    }
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
.home-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  &.scroll-bg {
    background: var(--zs-header-bg);
  }

  .wrapper {
    width: 1216px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        height: 20px;
        margin-right: 22px;
      }

      .game-slogn {
        height: 30px;
      }
    }
    .right {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 30px;
      .header-btns {
        display: flex;
        align-items: center;
        gap: 30px;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 128px;
          height: 32px;
          border-radius: 16px;
          background: var(--zs-header-btn-bg);
          font-size: 14px;
          color: var(--zs-header-btn-text-color);
          border: none;
          outline: none;
          &:hover {
            opacity: 0.9;
          }
          .icon {
            font-size: 18px;
            margin-right: 3px;
          }
        }
      }
      .search-box {
        position: relative;
        width: 261px;
        height: 32px;
        border-radius: 21px;
        background: var(--zs-input-bg);
        padding-right: 44px;
        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          outline: none;
          padding: 0 0 0 18px;
          font-weight: 400;
          font-size: 14px;
          color: var(--zs-input-text-color);
          line-height: 21px;
          &::placeholder {
            color: var(--zs-input-text-color);
          }
        }

        .icon {
          position: absolute;
          right: 17px;
          top: 6px;
          font-size: 20px;
          cursor: pointer;
          color: var(--zs-input-text-color);
          &:hover {
            opacity: 0.9;
          }
        }
      }
      .wiki-nav {
        .nav-box {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: var(--zs-nav-text-color);
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
          i {
            margin-right: 6px;
            font-size: 24px;
          }
        }
      }

      .user {
        position: relative;
        &:hover {
          .user-box {
            display: block;
            transform: translateX(-50%) scale(1);
          }
        }
        .red-point {
          position: absolute;
          right: -6px;
          top: -7px;
          display: inline-block;
          padding: 0 5px;
          word-break: keep-all;
          background: #cf4747;
          border-radius: 7px;
          border: 1px solid #ffffff;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
        }
        > .not-login {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
        > .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #fff;
          cursor: pointer;
        }
        .user-box {
          display: none;
          position: absolute;
          top: 25px;
          left: 16px;
          transform: translateX(-50%) scale(0.8);
          width: 268px;
          min-height: 210px;
          z-index: 1000;
          background: var(--zs-user-box-bg);
          border-radius: 15px;
          padding-top: 69px;
          transition: all 1s;
          .avatar {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: var(--zs-user-box-avatar-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              border: 4px solid #ffffff;
            }
          }

          .username {
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            color: var(--zs-user-box-name-color);
            line-height: 24px;
            text-align: center;
            margin-bottom: 23px;
          }

          .btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            .btn {
              position: relative;
              display: inline-block;
              width: 98px;
              height: 32px;
              background: var(--zs-user-box-btn-bg);
              border-radius: 21px;
              font-weight: 500;
              font-size: 14px;
              color: var(--zs-user-box-btn-color);
              line-height: 32px;
              text-align: center;
              &:hover {
                opacity: 0.9;
              }
            }
          }

          .logout {
            width: 100%;
            margin-top: 23px;
            padding: 18px 20px;
            font-weight: 400;
            font-size: 14px;
            color: var(--zs-user-box-logout-text-color);
            line-height: 21px;
            text-align: center;
            border-top: 1px solid var(--zs-user-box-logout-border-color);
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}
/deep/ .wxacode-popover {
  width: 188px !important;
  height: 264px !important;
  border-radius: 12px !important;
  padding: 0 !important;
  border: none !important;
  background: url("../../../../../assets/images/miniapp-msg-tips-bg.png") no-repeat !important;
  background-size: 188px 264px !important;
  box-shadow: none !important;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 65px 20px 18px;
    .title {
      width: 100%;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 17px;
      font-weight: 900;
      color: #435f6a;
      text-align: right;
      line-height: 17px;
      margin-bottom: 12px;
    }
    .tips {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      margin-bottom: 10px;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background: #dfdfdf;
      }
    }
    .qrcode {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
.my-msg {
  display: inline-block;
  position: relative;
  width: 98px;
  height: 32px;
  background: #d4d1cd;
  border-radius: 21px;
  font-weight: 500;
  font-size: 14px;
  color: #2a3035;
  line-height: 32px;
  text-align: center;
  &:hover {
    opacity: 0.9;
  }
  .red-point {
    position: absolute;
    right: -6px;
    top: -7px;
    display: inline-block;
    padding: 0 5px;
    word-break: keep-all;
    background: #cf4747;
    border-radius: 7px;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 14px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";