











































































































































































.apply-scrollbar {
  height: 400px;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.apply-info {
  width: 100%;
  .info-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
  }
  .info-item {
    font-size: 14px;
    line-height: 24px;
  }

  .apply-btns {
    margin: 32px 0;
    text-align: center;
  }
}
.form-content {
  padding: 20px 40px;
}
.form-item {
  margin-bottom: 30px;
}
.form-title {
  font-size: 14px;
  font-weight: 500;
  color: #171717;

  .tips {
    color: #999999;
    font-weight: 400;
    font-size: 12px;
  }
}

.form-input {
  height: 32px;
  margin-top: 4px;
  display: flex;
  align-items: center;

  input {
    height: 100%;
    flex: 1;
    border: none;
    border-bottom: 1px solid #ededed;
    &::placeholder {
      color: #d1d1d1;
    }
  }
}

.form-textarea {
  background: #f8f8f8;
  height: 140px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
  margin-top: 12px;
}
.apply-btn {
  width: 100%;
  height: 36px;
}
.btn-tips {
  font-size: 12px;
  color: #999;
  margin-top: 28px;
  a {
    color: #00afe8;
    &:hover {
      text-decoration: underline;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";