





















































































































































.letter-container {
  .user {
    display: flex;
    gap: 11px;
    margin-bottom: 20px;
    align-items: center;
    .avatar {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      object-fit: cover;
    }
    .name {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
  }

  .btns {
    margin-top: 26px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
.top-user-box {
  background: url("../../../assets/images/user-info-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 6px;

  .l-user-wrapper {
    display: flex;
    align-items: stretch;

    .user-avatar {
      position: relative;
      width: 120px;
      height: 120px;
      .avatar-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .avatar-ban {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    > .user-infos {
      min-height: 120px;
      padding-left: 28px;

      .username {
        font-size: 18px;
        font-weight: 600;
        color: #151515;
        margin-top: 10px;
        margin-bottom: 20px;

        span {
          font-size: 14px;
          color: #8b8b8b;
          font-weight: initial;
          margin-left: 10px;
        }
      }

      .sign {
        font-size: 16px;
        color: #404040;
        max-width: 500px;
        margin-bottom: 20px;
      }

      .edit-btn,
      .follow-btn {
        display: flex;
        align-items: center;
        border: 1px solid @basic;
        color: @basic;
        background: #fff;
        border-radius: 4px;
        height: 36px;
        padding: 0 14px;
        transition: 0.3s;

        &:hover {
          background: @basic-light;
        }

        &.active {
          background: @basic;
          color: #fff;
        }
      }
    }
  }

  .r-data-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #8b8b8b;

    > div {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #666666;
    }

    span {
      font-size: 28px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";