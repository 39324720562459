



















































.emoji-panel {
  width: 360px;
  height: 325px;
  user-select: none;
  /deep/ .el-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex-grow: 1;
    }
    .el-tabs__header {
      border-bottom-width: 0;
      .is-bottom {
        margin-top: 0;
      }
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        line-height: 36px;
      }
      .el-tabs__nav-prev {
        left: 4px;
      }
      .el-tabs__nav-next {
        right: 4px;
      }
    }
    .el-tabs__nav-scroll {
      background-color: #f2f2f2;
      .el-tabs__nav {
        border: none;
        .is-active {
          background-color: #fff;
        }
      }
      .el-tabs__item {
        height: 36px;
        line-height: initial;
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        border: none;
      }
    }
  }
}
.emoji-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 17px 0;
  row-gap: 17px;
  img {
    width: 56px;
    height: 56px;
  }
}
.tab-label {
  width: 22px;
  height: 22px;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";