.article-edit-box {
  width: 100%;
  height: 190px;
  border-radius: 10px;
  background: #f2f2f2;
  padding: 10px;
  font-size: 14px;
  user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  overflow: auto;
  resize: none;
  border: none;
}
.article-edit-box:empty:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url("~@/assets/images/icon-edit.png") no-repeat;
  margin-right: 7px;
  background-size: 16px 16px;
}
.article-edit-box:empty:after {
  content: attr(placeholder);
  color: #b7b7b7;
}
.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 14px;
}
.tool-bar .left-wrapper {
  display: flex;
  align-items: center;
}
.tool-bar .left-wrapper .img-update-btn {
  width: 30px;
  height: 30px;
  position: relative;
  line-height: 30px;
  text-align: center;
  background-size: 100% 100%;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
}
.tool-bar .left-wrapper .img-update-btn img {
  width: 26px;
  height: 26px;
}
.tool-bar .left-wrapper .img-update-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: 0;
}
.tool-bar .left-wrapper .video-update-btn {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  background-size: 100% 100%;
  margin-left: 15px;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
}
.tool-bar .left-wrapper .video-update-btn img {
  width: 26px;
  height: 26px;
}
.tool-bar .left-wrapper .video-update-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: 0;
}
.tool-bar .send-btn {
  width: 66px;
  height: 32px;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #05b7fe 0%, #139cfa 100%);
  border-radius: 6px 6px 6px 6px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
}
.img-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
}
.img-group .item {
  width: 60px;
  height: 60px;
  margin: 10px 10px 0 0;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 6px;
}
.img-group .item img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.img-group .item .close-btn {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.img-group .item .close-btn:hover {
  box-shadow: 0 0 6px #bbb;
}
.img-group .item .close-btn i {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-group .item .percent-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  color: #333;
  font-size: 12px;
}
.img-group .item .el-icon-video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
  text-shadow: 0 0 4px #bbb;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
