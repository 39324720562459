
































































































/deep/ .el-dialog__wrapper {
  backdrop-filter: blur(10px);
}
/deep/ .underage-dialog .el-dialog__header {
  display: none;
}
.dialog-container {
  .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 41px;
    margin-top: 20px;
    img {
      width: 227px;
      height: 34px;
    }
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    color: #171717;
    line-height: 20px;
    margin-bottom: 32px;
    p {
      text-align: center;
      margin: 0;
    }
  }
  .btns {
    text-align: center;
    margin-bottom: 20px;
    button {
      width: 400px;
      height: 37px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .text-btn {
      height: 20px;
      background-color: #fff;
      border: none;
      color: #bdbec1;
      margin-bottom: 20px;
    }
    .primary-btn {
      background: #00afe8;
      border: 1px solid #00afe8;
      color: #fff;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";