.gameInfo {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #f3f3f3;
}
.gameInfo img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.menu-wrapper /deep/ .el-menu {
  border-right: none;
}
.menu-wrapper /deep/ .el-menu .el-menu-item {
  border-right: 3px solid transparent;
}
.menu-wrapper /deep/ .el-menu .el-menu-item.is-active {
  border-right: 3px solid #00B64F;
  background: rgba(234, 255, 230, 0.5);
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
