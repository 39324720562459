













































































.www-secondary-base {
  padding-top: 70px;
  min-height: 100vh;
  .www-secondary-body {
    background: #fff;
    margin: auto;
    width: 1330px;
    min-height: 900px;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";