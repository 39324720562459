.manage-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.manage-page > .el-main {
  padding: 0;
  height: auto;
  flex-basis: 0;
}
.manage-container {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0;
}
.scrollBar {
  height: 100%;
  flex: 1;
}
.scrollBar /deep/ .el-scrollbar__wrap {
  overflow-x: auto;
}
.right-container {
  padding-top: 0;
  position: relative;
  z-index: 0;
}
.left-aside {
  width: 250px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #f3f3f3;
  position: relative;
  z-index: 1;
}
.left-aside /deep/ .scrollbar-wrapper {
  height: 100%;
}
.left-aside /deep/ .scrollbar-wrapper .el-scrollbar__wrap {
  overflow-x: auto;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
