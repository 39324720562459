

















































































































































































































































































































































































































































































































































































































































































/deep/ .dialog-normal {
  .login-title {
    position: absolute;
    z-index: 1;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
  }
  border-radius: 20px;
  background: linear-gradient(180deg, #fdffff 0%, #bdefff 100%);
  .el-dialog__header {
    padding: 22px 0;
  }
  .el-dialog__body {
    padding-top: 9px;
    background: url("../../../assets/images/login-bg-bottom.png") no-repeat;
    background-position: right bottom;
  }
  .el-dialog__headerbtn {
    background-image: url("./../../../assets/images/login-close.png");
    width: 36px;
    height: 36px;
    z-index: 1;
    background-size: contain;
    top: -12px;
    right: -12px;
    cursor: pointer;
    transition: transform 300ms;
    &:hover {
      transform: rotate(90deg);
    }
    .el-dialog__close {
      display: none;
    }
  }
}
/deep/ .dialog-verify {
  .login-title {
    position: static;
    transform: translateX(0);
  }
}
/deep/ .protocol-dialog {
  .footer {
    display: flex;
    justify-content: space-between;
    .no,
    .yes {
      width: 45%;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .no {
      color: #2fceff;
      background-color: #fff;
      border: 1px solid #2fceff;
    }
    .yes {
      color: #fff;
      background-color: #2fceff;
    }
  }
  .protocol-txt {
    color: #5a75a5;
    &:active {
      color: #5a75a5;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";