









































































































.gameInfo {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid @border-basic;

  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
}

.menu-wrapper {
  /deep/ .el-menu {
    border-right: none;

    .el-menu-item {
      border-right: 3px solid transparent;
    }

    .el-menu-item.is-active {
      border-right: 3px solid @basic;
      background: fade(@basic-light, 50%);
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";