.img-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-box {
  width: 100%;
  height: 100%;
}
.swiper-box .img-view-item {
  width: 100%;
  height: 100%;
}
.close-btn {
  font-size: 36px;
  color: #fff;
  border: none;
  background: transparent;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 100;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}
.img-view-pagination {
  color: #fff;
  font-size: 16px;
  bottom: 30px;
  text-shadow: 0 0 1px #8B8B8B;
}
.img-view-next,
.img-view-prev {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 30px;
}
.img-view-next {
  right: 50px;
}
.img-view-prev {
  left: 50px;
}
.wap-img-view .img-view-next,
.wap-img-view .img-view-prev {
  display: none;
}
.wap-img-view .close-btn {
  top: 10px;
  right: 10px;
  font-size: 24px;
}
.zoom-in {
  cursor: zoom-in;
}
.zoom-out {
  cursor: zoom-out;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
