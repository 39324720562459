




























































































































































































































.user-body {
  background-color: #f0f1f5;
  margin: 0;
  min-height: 100vh;
  padding-top: 54px;
  .wiki-content {
    padding-top: 16px;
    width: 960px;
    margin: auto;
  }
}

.user-main {
  display: flex;
  justify-content: space-between;
}

.nav-box {
  width: 170px;
}

.nav-bar {
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    border-bottom: 1px solid @border-basic;
    color: #666;
    font-size: 15px;
    transition: 0.3s;

    &:hover {
      color: #000;
    }

    .iconfont {
      font-size: 24px;
      margin-right: 12px;
      color: #d8d8d8;
    }

    &.router-link-active {
      color: #171717;

      .iconfont {
        color: #171717;
      }
    }
  }
}

.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";