.letter-container .user {
  display: flex;
  gap: 11px;
  margin-bottom: 20px;
  align-items: center;
}
.letter-container .user .avatar {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  object-fit: cover;
}
.letter-container .user .name {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.letter-container .btns {
  margin-top: 26px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.top-user-box {
  background: url("../../../assets/images/user-info-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 6px;
}
.top-user-box .l-user-wrapper {
  display: flex;
  align-items: stretch;
}
.top-user-box .l-user-wrapper .user-avatar {
  position: relative;
  width: 120px;
  height: 120px;
}
.top-user-box .l-user-wrapper .user-avatar .avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-box .l-user-wrapper .user-avatar .avatar-ban {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-box .l-user-wrapper > .user-infos {
  min-height: 120px;
  padding-left: 28px;
}
.top-user-box .l-user-wrapper > .user-infos .username {
  font-size: 18px;
  font-weight: 600;
  color: #151515;
  margin-top: 10px;
  margin-bottom: 20px;
}
.top-user-box .l-user-wrapper > .user-infos .username span {
  font-size: 14px;
  color: #8b8b8b;
  font-weight: initial;
  margin-left: 10px;
}
.top-user-box .l-user-wrapper > .user-infos .sign {
  font-size: 16px;
  color: #404040;
  max-width: 500px;
  margin-bottom: 20px;
}
.top-user-box .l-user-wrapper > .user-infos .edit-btn,
.top-user-box .l-user-wrapper > .user-infos .follow-btn {
  display: flex;
  align-items: center;
  border: 1px solid #00B64F;
  color: #00B64F;
  background: #fff;
  border-radius: 4px;
  height: 36px;
  padding: 0 14px;
  transition: 0.3s;
}
.top-user-box .l-user-wrapper > .user-infos .edit-btn:hover,
.top-user-box .l-user-wrapper > .user-infos .follow-btn:hover {
  background: #EAFFE6;
}
.top-user-box .l-user-wrapper > .user-infos .edit-btn.active,
.top-user-box .l-user-wrapper > .user-infos .follow-btn.active {
  background: #00B64F;
  color: #fff;
}
.top-user-box .r-data-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #8b8b8b;
}
.top-user-box .r-data-wrapper > div {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666666;
}
.top-user-box .r-data-wrapper span {
  font-size: 28px;
  font-weight: bold;
}
.top-user-box .r-data-wrapper p {
  font-size: 14px;
  margin-top: 8px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
