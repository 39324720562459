
































































































































































































































































































































/deep/ .dark {
  background: linear-gradient(to bottom, #2d3344, #343c45);
  .el-dialog__header {
    padding: 20px 20px 0 20px;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 16px;
      right: 20px;
      &:hover .el-dialog__close {
        color: #fff;
      }
    }
  }
  .el-dialog__close {
    color: #ffffff;
  }
  .article-edit-box {
    background-color: #20242f;
    color: #bebebe;
  }
  .title {
    color: #fff;
    padding-top: 20px;
    border-top: 1px solid rgba(233, 233, 233, 0.12);
  }
  .ques-list .item {
    background-color: transparent;
    border-bottom: 1px solid rgba(233, 233, 233, 0.04);
    border-radius: 0;
    .item-title {
      color: #bebebe;
      border-bottom: none;
    }
    .bottom .user {
      color: #999999;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";