@import "global";
@import "./title";

* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont,
    "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial;
  word-break: break-word;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
  // transition: all ease 0.3s;
}

html {
  min-height: 100vh;
  background: #f9fafe;
  // transition: all ease 0.3s;
}

a {
  text-decoration: none;
}

body {
  font-size: 14px;
  // transition: all ease 0.3s;
}

input,
textarea {
  border-radius: 0;
}

.mouse-copy {
  cursor: url("./../images/mouse-copy.png"), default;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

input,
button,
textarea {
  -webkit-appearance: none;
  outline: none;
}

button {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.user-name-link {
  color: @basic;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    text-decoration: underline;
  }
}

.W100 {
  width: 100%;
}

.W100px {
  width: 100px;
}

.W120px {
  width: 120px;
}

.W150px {
  width: 150px;
}

.W200px {
  width: 200px;
}

.W400px {
  width: 400px;
}

.H10px {
  height: 10px;
}

.H20px {
  height: 20px;
}

.tCenter {
  text-align: center;
}

.tRight {
  text-align: right;
}

.tLeft {
  text-align: left;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.mT10 {
  margin-top: 10px;
}

.mT12 {
  margin-top: 12px;
}

.mT20 {
  margin-top: 20px;
}

.mL5 {
  margin-left: 5px;
}

.mT5 {
  margin-top: 5px;
}

.mL10 {
  margin-left: 10px;
}

.mL12 {
  margin-left: 12px;
}

.mL20 {
  margin-left: 20px;
}

.mR20 {
  margin-right: 20px;
}
.mR3 {
  margin-right: 3px;
}

.mR10 {
  margin-right: 10px;
}

.mR12 {
  margin-right: 12px;
}

.mB10 {
  margin-bottom: 10px;
}

.mB12 {
  margin-bottom: 12px;
}

.mT16 {
  margin-top: 16px;
}

.mB16 {
  margin-bottom: 16px;
}

.mB20 {
  margin-bottom: 20px;
}
.mR8 {
  margin-right: 8px;
}
.mL8 {
  margin-left: 8px;
}

.iconfont {
  font-size: inherit;
}

.secondary-content img[lazy="loading"],
.wiki-detail-content img[lazy="loading"] {
  width: 100% !important;
  height: auto !important;
}

//权限控制隐藏
.has-hidden {
  display: none !important;
}

//-----------重置ele部分样式
.el-dialog {
  border-radius: 6px;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  color: #000;
}

.el-avatar {
  vertical-align: middle;
}

.el-dialog__body {
  padding: 10px 20px;
}

.el-dialog--center .el-dialog__body {
  padding: 10px 20px;
}

.el-button--primary {
  color: @text-white;
  background-color: @button-bg-color;
  border-color: @button-bg-color;
}

.el-button--mini {
  padding: 5px 10px;
}

.el-button--primary:focus,
.el-button--primary:hover {
  color: @text-white;
  background-color: @button-bg-color;
  border-color: @button-bg-color;
}

.el-message-box {
  max-width: 90%;
}

.el-select .el-tag {
  box-sizing: border-box;
  border-color: transparent;
  margin: 2px 0 2px 6px;
  background-color: #f0f2f5;
  display: flex;
  max-width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.el-select__tags-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-select .el-tag__close.el-icon-close {
  top: 1px;
  left: 6px;
  flex-shrink: 0;
}

//------------重置ele部分样式--end

.tag-color {
  border-radius: 4px;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
  border: 1px solid;

  &.tag-color-purple {
    border-color: #b936f0;
    color: #b936f0;
  }

  &.tag-color-gold {
    border-color: #d1ad25;
    color: #d1ad25;
  }

  &.tag-color-cyan {
    border-color: #1ebfa7;
    color: #1ebfa7;
  }

  &.tag-color-green {
    border-color: #00cb1e;
    color: #00cb1e;
  }

  &.tag-color-brown {
    border-color: #ba7a47;
    color: #ba7a47;
  }

  &.tag-color-blue {
    border-color: #3694ff;
    color: #3694ff;
  }

  &.tag-color-deep-red {
    border-color: #993a3a;
    color: #993a3a;
  }

  &.tag-color-orange {
    border-color: #ec9d00;
    color: #ec9d00;
  }

  &.tag-color-red {
    border-color: #ff6060;
    color: #ff6060;
  }

  &.tag-color-gray {
    border-color: #dbdbdb;
    color: #dbdbdb;
  }
}

.span-point {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 0;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;

  &.span-point-purple {
    background: #b936f0;
  }

  &.span-point-gold {
    background: #d1ad25;
  }

  &.span-point-cyan {
    background: #1ebfa7;
  }

  &.span-point-green {
    background: #00cb1e;
  }

  &.span-point-brown {
    background: #ba7a47;
  }

  &.span-point-blue {
    background: #3c55d6;
  }

  &.span-point-deep-red {
    background: #993a3a;
  }

  &.span-point-orange {
    background: #f8a429;
  }

  &.span-point-red {
    background: #e56352;
  }

  &.span-point-gray {
    background: #dbdbdb;
    color: #868686;
  }
}

.article-row-tag {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  gap: 10px;
  .title {
    .ellipsis;
    flex: 1;
    color: #424242;
  }
}

.article-item-btn {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  color: #424242;
  width: 100%;
  height: 30px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  padding: 0 4px;
  border-radius: 2px;
  line-height: 28px;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
    margin-top: 2px;
  }

  span {
    .ellipsis;
    width: calc(100% - 32px);
    display: block;
  }

  & + .article-item-btn {
    margin-top: 8px;
  }
}

.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: @middle-z-index;

  .app-bar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #fff;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 20px;
    color: #666;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: @text-basic;
  }

  .logo {
    height: 16px;
    width: 150px;
    background: url("../../assets/images/logo2.png") no-repeat left center;
    background-size: auto 100%;
  }

  .bar-r-wrapper {
    display: flex;
    align-items: center;

    .user {
      color: #fff;

      img {
        width: 24px;
        height: 24px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .search-btn,
    .red-point,
    .user {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      color: #fff;
      font-size: 20px;
    }

    .red-point {
      position: relative;

      span {
        position: absolute;
        top: 0;
        left: 20px;
        display: flex;
        align-items: center;
        padding: 0 4px;
        word-break: keep-all;
        background: red;
        border-radius: 10px;
        height: 16px;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

.publish-miniapp-tips {
  width: 355px;
  min-width: 100px;
  height: 216px;
  background: url("../../assets/images/miniapp-publish-tips-bg.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 65px 12px 11px 14px;
  overflow: visible;
  border: none;
  box-shadow: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  .el-message__icon {
    display: none;
  }
  .el-message__closeBtn {
    top: 62px;
    right: -36px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    line-height: 24px;
    &::before {
      font-size: 16px;
      color: #fff;
    }
  }

  .miniapp-tips-content {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #216b7e;
      line-height: 22px;
      font-weight: 900;
      margin-bottom: 18px;
      img {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
    }
    .content {
      width: 100%;
      display: flex;
      gap: 19px;
      .qrcode {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        background: #ffffff;
        img {
          width: 100%;
        }
      }
      .tips {
        flex: 1;
        width: 100%;
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 6px;
        }
        .desc {
          font-weight: 400;
          font-size: 11px;
          color: #666666;
          line-height: 16px;
          margin-bottom: 23px;
        }
        .tags {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            display: inline-block;
            width: 68px;
            height: 24px;
            background: #e8f7ff;
            border-radius: 14px;
            font-weight: 400;
            font-size: 11px;
            color: #4e7d95;
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
}
@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";