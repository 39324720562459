.svg-icon {
  display: inline-block;
  vertical-align: middle;
}
.svg-icon .icon {
  width: 1em;
  height: 1em;
  line-height: 1em;
  fill: currentColor;
  overflow: hidden;
  vertical-align: middle;
  margin-top: -0.2em;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
