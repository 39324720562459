.breadcrumb {
  height: 40px;
  line-height: 40px;
}
 /deep/ .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: 400;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
